<template>
  <div class="c-app d-flex align-items-center min-vh-100 min-v">
    <template v-if="showResult">
      <CContainer fluid>
        <CRow class="justify-content-center p-0">
          <CCol class="p-0">
            <CCard class="mx-0 mb-0 p-0">
              <CCardBody class="p-4">
                <CForm>
                  <CRow>
                    <CCol md="4" />
                    <CCol md="8">
                      <h1>Patient Details</h1>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="11">
                      <h4>Patient Name: {{ this.patientName }}</h4>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="11">
                      <h4>QR Code:</h4>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="2" />
                    <CCol md="10">
                      <img
                        v-bind:src="'data:image/png;base64, ' + this.imageb64"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12">
                      <CButton
                        color="success"
                        class="mt-2"
                        @click="this.downloadImage"
                        block
                      >
                        Download QRCode
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </template>
    <template v-if="showForm">
      <CContainer fluid>
        <CRow class="justify-content-center p-0">
          <CCol class="p-0">
            <CCard class="mx-0 mb-0 p-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1>Patient Regisitration</h1>
                  <CAlert color="danger" :show.sync="showError" closeButton>
                    Validation Failed or Error when Registering
                    <CProgress
                      :max="10"
                      :value="showError"
                      height="3px"
                      color="danger"
                      animate
                    />
                  </CAlert>
                  <CRow>
                    <CCol md="6">
                      <CFormGroup
                        wrapperClasses="input-group pt-2"
                        description="ex. (012) 345-6789"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-phone" />
                        </template>
                        <template #label> Telephone Number  </template>
                        <template #input>
                          <masked-input
                            type="tel"
                            name="phone"
                            class="form-control"
                            v-model="phoneNumber"
                            :mask="[
                              '(',
                              /[0]/,
                              /\d/,
                              /\d/,
                              ')',
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]"
                            :guide="true"
                            placeholderChar="#"
                          />
                        </template>
                     </CFormGroup>   
                     <div class="text-danger" v-if="$v.phoneNumber.$invalid">Phone Number is required</div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="2" class="mt-1">
                      <div>Patient Name</div>
                      <v-select
                        :options="titles"
                        @search="onSearchTitles"
                        label="label"
                        :filterable="false"
                        v-model="title"
                        placeholder="Select Title"
                      >
                        <template slot="no-options">
                          Type to search Titles...
                        </template>
                      </v-select>
                      <div class="text-danger" v-if="$v.title.$invalid">Title is required</div>
                    </CCol>
                    <CCol md="4">
                      <CInput
                        label=" "
                        placeholder="Patient Name"
                        v-model="patientName"
                        :isValid="!$v.patientName.$invalid"
                        invalidFeedback="Name is required"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-user" />
                        </template>
                      </CInput>
                    </CCol>
                    <CCol md="6">
                      <CInput
                        label="Name with Initials"
                        placeholder="Name with Initials"
                        v-model="nameWithInitials"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                      </CInput>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Address - Number"
                        placeholder="Address - Number"
                        v-model="addressNumber"
                        :isValid="!$v.addressNumber.$invalid"
                        invalidFeedback="Address Number is required"
                      />
                    </CCol>
                    <CCol md="6" class="mt-2">
                      <div>Country</div>
                      <v-select
                        :options="countries"
                        @search="onSearchCountries"
                        label="label"
                        :filterable="false"
                        v-model="country"
                        placeholder="Select Country"
                      >
                        <template slot="no-options">
                          Type to search Country...
                        </template>
                      </v-select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Address - Street"
                        placeholder="Address - Street"
                        v-model="addressStreet"
                        :isValid="!$v.addressStreet.$invalid"
                        invalidFeedback="Address Street is required"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        label="NIC Number"
                        v-model="nicNumber"
                        placeholder="NIC Number"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Address - City"
                        placeholder="Address - City"
                        v-model="addressCity"
                        :isValid="!$v.addressCity.$invalid"
                        invalidFeedback="Address City is required"
                      />
                     </CCol>
                    <CCol md="6">
                      <CInput
                        label="Passport Number"
                        placeholder="Passport Number"
                        v-model="passportNumber"
                      />
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol md="6"> </CCol>
                    <CCol md="6"> </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Email"
                        placeholder="Email"
                        autocomplete="email"
                        prepend="@"
                        v-model="email"
                        :isValid="!$v.email.$invalid"
                        invalidFeedback="Email is required"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        label="Occupation"
                        v-model="occupation"
                        placeholder="Occupation"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Date of Birth"
                        type="date"
                        v-model="dateOfBirth"
                      />
                    </CCol>
                    <CCol md="2" class="mt-1">
                      <div>Age Type</div>
                      <v-select
                        :options="ageTypes"
                        label="label"
                        :filterable="false"
                        v-model="ageType"
                        placeholder="Age Type"
                      >
                      </v-select>
                       <div class="text-danger" v-if="$v.ageType.$invalid">Age Type is required</div>
                    </CCol>
                    <CCol md="4">
                      <div>Age</div>
                      <v-select
                        :options="ages"
                        label="label"
                        :filterable="false"
                        v-model="age"
                        placeholder="Age"
                      >
                      </v-select>
                      <div class="text-danger" v-if="$v.age.$invalid">Age is required</div>
                    
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <div>Gender</div>
                      <v-select
                        :options="genders"
                        @search="onSearchGenders"
                        label="label"
                        :filterable="false"
                        v-model="gender"
                        placeholder="Select Gender"
                      >
                        <template slot="no-options">
                          Type to search Genders...
                        </template>
                      </v-select>
                      <div class="text-danger" v-if="$v.gender.$invalid">Gender is required</div>
                    
                    </CCol>
                    <CCol md="6">
                      <div>Race</div>
                      <v-select
                        :options="races"
                        @search="onSearchRaces"
                        label="label"
                        :filterable="false"
                        v-model="race"
                        placeholder="Select Race"
                      >
                        <template slot="no-options">
                          Type to search Races...
                        </template>
                      </v-select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" class="mt-2">
                      <div>Civil Status</div>
                      <v-select
                        :options="civilStatuses"
                        @search="onSearchCivilStatuses"
                        label="label"
                        :filterable="false"
                        v-model="civilStatus"
                        placeholder="Select Civil Status"
                      >
                        <template slot="no-options">
                          Type to search Civil Statuses...
                        </template>
                      </v-select>
                    </CCol>
                    <CCol md="6" class="mt-2">
                      <div>Blood Group</div>
                      <v-select
                        :options="bloodGroups"
                        @search="onSearchBloodGroups"
                        label="label"
                        :filterable="false"
                        v-model="bloodGroup"
                        placeholder="Select Blood Groups"
                      >
                        <template slot="no-options">
                          Type to search Blood Groups...
                        </template>
                      </v-select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <p></p>
                      <h3>Next Kin</h3>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Name"
                        placeholder="Name"
                        v-model="kinsName"
                        :isValid="!$v.kinsName.$invalid"
                        invalidFeedback="Kins Name is required"
                      />
                    </CCol>
                    <CCol md="6"> </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Address - Number"
                        placeholder="Address - Number"
                        v-model="kinsAddressNumber"
                        :isValid="!$v.kinsAddressNumber.$invalid"
                        invalidFeedback="Kins Address Number is required"
                      />
                      <CInput
                        label="Address - Street"
                        placeholder="Address - Street"
                        v-model="kinsAddressStreet"
                        :isValid="!$v.kinsAddressStreet.$invalid"
                        invalidFeedback="Kins Address Street is required"
                      />
                      <CInput
                        label="Address - City"
                        placeholder="Address - City"
                        v-model="kinsAddressCity"
                        :isValid="!$v.kinsAddressCity.$invalid"
                        invalidFeedback="Kins Address City is required"
                      />
                    </CCol>
                    <CCol md="6">
                      <CInput
                        label="NIC Number"
                        placeholder="NIC Number"
                        v-model="kinsNicNumber"
                      />
                      <CInput
                        label="Relationship"
                        placeholder="Relationship"
                        v-model="kinsRelationship"
                      />
                      <CInput
                        label="Telephone Number"
                        placeholder="Telephone Number"
                        v-model="kinsTelephoneNumber"
                      />
                    </CCol>
                  </CRow>
                  <CRow />
                  <CRow />
                  <CRow>
                    <CCol md="6">
                      <div>Consultant</div>
                      <v-select
                        :options="consultants"
                        @search="onSearchConsultants"
                        label="label"
                        :filterable="false"
                        v-model="consultant"
                        placeholder="Select Consultant"
                      >
                        <template slot="no-options">
                          Type to search Consultant...
                        </template>
                      </v-select>
                      <div class="text-danger" v-if="$v.consultant.$invalid">Consultant is required</div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" hidden>
                      <div>Room Category</div>
                      <v-select
                        :options="bedRoomCategories"
                        @search="onSearchBedRoomCategories"
                        label="label"
                        :filterable="false"
                        v-model="bedRoomCategory"
                        placeholder="Select Room Category"
                      >
                        <template slot="no-options">
                          Type to search Room Category...
                        </template>
                      </v-select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CInput
                        label="Insurance Policy Number"
                        placeholder="Insurance Policy Number"
                        v-model="insurancePolicyNumber"
                      />
                    </CCol>
                    <CCol md="6" class="mt-2">
                      <div>Insurance Company</div>
                      <v-select
                        :options="debtors"
                        @search="onSearchDebtors"
                        label="label"
                        :filterable="false"
                        v-model="debtor"
                        placeholder="Select Insurance Company"
                      >
                        <template slot="no-options">
                          Type to search Insurance Company...
                        </template>
                      </v-select>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <p></p>
                      <h3>Medical Condition</h3>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="4" class="mt-2">
                      <div>01. Abdominal pain ?</div>
                    </CCol>
                    <CCol md="1" class="mt-2">
                    </CCol>
                    <CCol md="1" class="mt-2">
                     <CInputCheckbox
                        label=" "
                        :checked.sync="abdominalPain"
                       />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="4" class="mt-2">
                      <div>02. Vaginal Discharge</div>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="1" class="mt-2">
                    </CCol>
                    <CCol md="4" class="mt-2">
                      <div>Bleeding ?</div>
                    </CCol>
                    <CCol md="1" class="mt-2">
                     <CInputCheckbox
                        label=" "
                        :checked.sync="vaginalBleeding"
                       />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="1" class="mt-2">
                    </CCol>
                    <CCol md="4" class="mt-2">
                      <div>Watery discharge ?</div>
                    </CCol>
                    <CCol md="1" class="mt-2">
                     <CInputCheckbox
                        label=" "
                        :checked.sync="wateryDischarge"
                       />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="4" class="mt-2">
                      <div>03. Satisfactory Fetal movements ?</div>
                    </CCol>
                    <CCol md="1" class="mt-2">
                    </CCol>
                    <CCol md="1" class="mt-2">
                     <CInputCheckbox
                        label=" "
                        :checked.sync="fetalMovement"
                       />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" class="mt-2">
                      <CInput
                        label="04. Other Concerns ?"
                        placeholder="Specify ..."
                        v-model="otherConcern"
                      />
                    </CCol>
                  </CRow>
                  <CButton
                    color="success"
                    class="mt-2"
                    :disabled="submitted"
                    @click="this.submitForm"
                    block
                  >
                    Submit
                  </CButton>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </template>
  </div>
</template>
<style>
.d-center {
  display: flex;
  align-items: center;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import MaskedInput from "vue-text-mask";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const checkPhone = (value) => !value.includes('#')

export default {
  name: "RegisterForAdmission",
  inject: ["api"],
  mixins: [validationMixin],
  components: {
    MaskedInput,
    vSelect,
  },
  data() {
    return {
      showForm: true,
      showError: 0,
      showResult: false,
      phoneNumber: null,
      patientName: null,
      nameWithInitials: null,
      race: null,
      bedRoomCategory: null,
      nicNumber: null,
      kinsNicNumber: null,
      passportNumber: null,
      email: null,
      occupation: null,
      kinsName: null,
      dateOfBirth: null,
      insurancePolicyNumber: null,
      kinsRelationship: null,
      kinsTelephoneNumber: null,
      kinsAddressNumber: null,
      kinsAddressStreet: null,
      kinsAddressCity: null,
      addressNumber: null,
      addressStreet: null,
      addressCity: null,
      debtor: null,
      country: null,
      consultant: null,
      bloodGroup: null,
      gender: null,
      civilStatus: null,
      title: null,
      area: null,
      ageType: null,
      age: null,
      image: null,
      imageb64: null,
      imageName: null,
      submitted: false,
      abdominalPain: false,
      fetalMovement: false,
      vaginalBleeding: false,
      wateryDischarge: false,
      otherConcern: null,
      races: [],
      bedRoomCategories: [],
      debtors: [],
      countries: [],
      consultants: [],
      genders: [],
      titles: [],
      areas: [],
      ageTypes: [
        { value: "D", label: "Days" },
        { value: "M", label: "Months" },
        { value: "Y", label: "Years" },
      ],
      ages: [...Array(100).keys()]
        .map((x) => x + 1)
        .map((x) => {
          var container = {};
          container.label = x;
          container.value = x;
          return container;
        }),
      civilStatuses: [],
      bloodGroups: [],
    };
  },
  validations: {
    phoneNumber: {
      required, checkPhone
    },
    patientName: {
      required,
    },
    email: {
      email,
      required,
    },
    title: {
      required,
    },
    kinsName: {
      required,
    },
    kinsAddressNumber: {
      required,
    },
    kinsAddressStreet: {
      required,
    },
    kinsAddressCity: {
      required,
    },
    addressNumber: {
      required,
    },
    addressStreet: {
      required,
    },
    addressCity: {
      required,
    },
    consultant: {
      required,
    },
    gender: {
      required,
    },
    ageType: {
      required,
    },
    age: {
      required,
    },
  },
  computed: {
    address: function () {
      return (
        this.addressNumber +
        " , " +
        this.addressStreet +
        " , " +
        this.addressCity
      );
    },
    isValid() {
      return !this.$v.$invalid;
    },
    isDirty() {
      return this.$v.$anyDirty;
    },
    kinAddress: function () {
      return (
        this.kinAddressNumber +
        " , " +
        this.kinAddressStreet +
        " , " +
        this.kinAddressCity
      );
    },
    getDaysInMonth: function (month, year) {
      return new Date(year, month, 0).getDate();
    },
  },
  methods: {
    startup() {
      this.searchRaces('');
      this.searchCivilStatuses('');
      this.searchBedRoomCategories('');
      this.searchCountries('');
      this.searchGenders('');
      this.searchTitles('');
      this.searchAreas('');
      this.searchBloodGroups('');
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async searchRaces(search) {
      const result = await this.api.get("/races", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.races = result.data;
      }
    },
    async searchCountries(search) {
      const result = await this.api.get("/countries", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.countries = result.data;
      }
    },
    async searchAreas(search) {
      const result = await this.api.get("/areas", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.areas = result.data;
      }
    },
    async searchTitles(search) {
      const result = await this.api.get("/titles", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.titles = result.data;
      }
    },
    async searchGenders(search) {
      const result = await this.api.get("/genders", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.genders = result.data;
      }
    },
    async searchBedRoomCategories(search){
      const result = await this.api.get("/bedroomcategories", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.bedRoomCategories = result.data;
      }
    },
    async searchCivilStatuses(search){
      const result = await this.api.get("/civilstatuses", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.civilStatuses = result.data;
      }
    },
    async searchBloodGroups(search) {
      const result = await this.api.get("/bloodgroups", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.bloodGroups = result.data;
      }
    },
    async onSearchRaces(search, loading) {
      loading(true);
      await this.searchRaces(search);
      loading(false);
    },
    async onSearchBedRoomCategories(search, loading) {
      loading(true);
      await this.searchBedRoomCategories(search);
      loading(false);
    },
    async onSearchCountries(search, loading) {
      loading(true);
      const result = await this.api.get("/countries", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.countries = result.data;
      }
      loading(false);
    },
    async onSearchConsultants(search, loading) {
      loading(true);
      const result = await this.api.get("/consultants", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.consultants = result.data;
      }
      loading(false);
    },
    async onSearchDebtors(search, loading) {
      loading(true);
      const result = await this.api.get("/debtors", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.debtors = result.data;
      }
      loading(false);
    },
    async onSearchGenders(search, loading) {
      loading(true);
      const result = await this.api.get("/genders", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.genders = result.data;
      }
      loading(false);
    },
    async onSearchTitles(search, loading) {
      loading(true);
      const result = await this.api.get("/titles", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.titles = result.data;
      }
      loading(false);
    },
    async onSearchBloodGroups(search, loading) {
      loading(true);
      const result = await this.api.get("/bloodgroups", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.bloodGroups = result.data;
      }
      loading(false);
    },
    async onSearchAreas(search, loading) {
      loading(true);
      const result = await this.api.get("/areas", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.areas = result.data;
      }
      loading(false);
    },
    async onSearchCivilStatuses(search, loading) {
      loading(true);
      const result = await this.api.get("/civilstatuses", {
        params: { filter: search },
      });
      if (result.data !== null) {
        this.civilStatuses = result.data;
      }
      loading(false);
    },
    async submitForm() {
      if (!this.isValid) {
        this.scrollToTop();
        this.showError = 10;
      } else {
        var body = {
          telephone: this.phoneNumber,
          patientName: this.title.label + this.patientName,
          patientOtherName: this.nameWithInitials,
          addressNumber: this.addressNumber,
          addressStreet: this.addressStreet,
          addressCity: this.addressCity,
          areaCode: this.area === null ? null : this.area.value,
          countryCode: this.country === null ? null : this.country.value,
          nicNumber: this.nicNumber,
          passportNumber: this.passportNumber,
          email: this.email,
          occupation: this.occupation,
          dateOfBirth: new Date(this.dateOfBirth).toISOString(),
          age: this.age === null ? null : this.age.value,
          ageType: this.ageType === null ? null : this.ageType.value,
          gender: this.gender === null ? null : this.gender.value,
          raceCode: this.race === null ? null : this.race.value,
          kinsName: this.kinsName,
          kinsAddressNumber: this.kinsAddressNumber,
          kinsAddressStreet: this.kinsAddressStreet,
          kinsAddressCity: this.kinsAddressCity,
          kinsNicNumber: this.kinsNicNumber,
          kinsPassportNumber: this.kinsPassportNumber,
          kinsTelephone: this.kinsTelephone,
          vaginalBleeding: this.vaginalBleeding,
          fetalMovement: this.fetalMovement,
          wateryDischarge: this.wateryDischarge,
          otherConcern: this.otherConcern,
          abdominalPain: this.abdominalPain,
          consultantInCharge:
            this.consultant === null ? null : this.consultant.value,
          bedroomCategory:
            this.bedRoomCategory === null ? null : this.bedRoomCategory.value,
          policyNumber: this.insurancePolicyNumber,
          accountCode: this.debtor === null ? null : this.debtor.value,
        };
        const result = await this.api.post("/register", body);
        if (result.data !== null) {
          if (result.data.status === true) {
            this.submitted = true;
            const imageStr = result.data.image;
            this.imageb64 = imageStr;
            var byteString = atob(imageStr);
            this.image = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(this.image);

            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            this.imageName = result.data.result.trim() + ".png";
            this.showForm = false;
            this.showResult = true;
          }
        } else {
          this.showError = 10;
        }
      }
    },
    downloadImage() {
      const url = window.URL.createObjectURL(
        new Blob([this.image], { type: "octet/stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = this.imageName;
      document.body.appendChild(link);
      link.click();
    },
    checkIfValid (field) {
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field === '')
    },
  },
  created: function() {
    this.startup();
  },
};
</script>